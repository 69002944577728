// Import libs
import React from "react";

// Import common components
import {
	Accordion,
	AccordionGroup,
	Button,
	ButtonNote,
	ContactForm,
	Container,
	Heading,
	ImageFigure,
	ImageGroup,
	Indicator,
	IndicatorGroup,
	Paragraph,
	Plan,
	PlanGroup,
	Testemonial,
	TestemonialGroup
} from "@windish/shared";

// Import local components
import Layout from "../components/Layout";
import Seo from "../components/Seo";

// Import page-specific CSS
import "./index.css";
import { StaticImage } from "gatsby-plugin-image";

// Page constants
const pageTitle = "Service of Aljaž Vindiš";
const pageDescription = "Reduce the risk of developing a new data product";

// Page
export default function Index() {

	// Scroll to contact form, triggered by the component
	function scrollToContact() {
		document.getElementById('contact').scrollIntoView();
	};

	// Render page
	return (
		<Layout pageTitle={pageTitle}>
			<Container>
				<div className="prestudy-costs">
					<div className="prestudy-costs__guides">
						<div className="prestudy-costs__guide"></div>
						<div className="prestudy-costs__guide"></div>
						<div className="prestudy-costs__guide"></div>
						<div className="prestudy-costs__guide"></div>
						<div className="prestudy-costs__guide"></div>
						<div className="prestudy-costs__guide"></div>
						<div className="prestudy-costs__guide"></div>
						<div className="prestudy-costs__guide"></div>
					</div>
					<div className="prestudy-costs__entries">
						<div className="prestudy-costs__traditional">
							<p className="prestudy-costs__traditional-title">Cost of validating in production</p>
							<span className="prestudy-costs__traditional-graph">&nbsp;</span>
						</div>
						<div className="prestudy-costs__prestudy">
							<p className="prestudy-costs__prestudy-title">Cost of validating with Prestudy</p>
							<span className="prestudy-costs__prestudy-graph">&nbsp;</span>
						</div>
					</div>
				</div>
				<Heading as="h2" size="xxl">{pageDescription}</Heading>
				<Paragraph>Would your latest initiative be a worthwhile pursuit or a costly mistake? Get the answer in a cost-effective way, using a modern product development methodology that leverages rapid prototyping and pre-production validation.</Paragraph>
				<ButtonNote>
					<Button type="cta" icon="event" label="Book your introduction" action={scrollToContact} />
					<Paragraph small={true} muted={true}>30 min call • Free of charge • No strings attached</Paragraph>
				</ButtonNote>
			</Container>
			<Container id="problem">
				<Heading as="h3" size="xl">You face hard questions ...</Heading>
				<StaticImage
					src="../images/aljaz-vindis_questions.jpg"
					alt="A person, alone, in front of a whiteboard."
				/>
				<div className="questions">
					<Paragraph muted={true}>Are we solving the right problem, at all? Do we have access to all relevant stakeholders? Which features would the users really need? What would be the best way to present our data? Would our customers pay for it? What technical obstacles may we stumble upon? How long will it take to launch? How much would it cost? Can I secure the investment?</Paragraph>
					<div className="fade-questions">&nbsp;</div>
				</div>
			</Container>
			<Container id="solution">
				<Heading as="h3" size="xl">If you could only have ...</Heading>
				<ImageFigure
					caption="A prototype that makes it easy to communicate and validate your proposed product.">
					<StaticImage
						src="../images/aljaz-vindis_wayfinder-system_cover.jpg"
						alt="Two people looking at a prototype."
					/>
				</ImageFigure>
				<ImageFigure
					caption="A well-defined user story map, scoped and estimated for MVP with confidence.">
					<StaticImage
						src="../images/aljaz-vindis_user-story-map.png"
						alt="A user story map."
					/>
				</ImageFigure>
				<ImageFigure
					caption="A convincing funding pitch that helps you secure the resources needed and start production.">
					<StaticImage
						src="../images/aljaz-vindis_pitch.jpg"
						alt="A person making the product pitch."
					/>
				</ImageFigure>
			</Container>
			<Container id="solutions">
				<Heading as="h3" size="xl">Well, what if I told you ...</Heading>
				<IndicatorGroup>
					<Indicator
						value="3"
						description="day workshop helps the team align their product vision, and outline the prototype."
					/>
					<Indicator
						value="3-4"
						description="weeks of work can be enought to iterate a fully functional, demoable prototype."
					/>
					<Indicator
						value="5"
						description="user tests uncover the major issues with the prototype, and help resolve them."
					/>
					<Indicator
						value="1-3"
						description="dedicated people is all it takes to go through all the neccesarry stages."
					/>
				</IndicatorGroup>
			</Container>
			<Container id="references">
				<Heading as="h3" size="xl">I helped them succeed</Heading>
				<ImageGroup images={2}>
					<ImageFigure
						caption="Leading manufacturing company from Slovenia used my prestudy to succesfully refine the feature-set and user experience of their collaborative robot user interface.">
						<StaticImage
							src="../images/aljaz-vindis_collaborative-robots_cover.jpg"
							alt=""
						/>
					</ImageFigure>
					<ImageFigure
						caption="Leading global telecom OEM used my prestudy to validate a long-tail IoT data plans' sales platform, secure funding for its development and define the initial roadmap.">
						<StaticImage
							src="../images/aljaz-vindis_iot-marketplace_cover.jpg"
							alt=""
						/>
					</ImageFigure>
					<ImageFigure
						caption="Leading telecom OEM used my prestudy to pivot their IoT platform development: increasing their addressable market with a solution for easy to use, fast IoT prototyping environment.">
						<StaticImage
							src="../images/aljaz-vindis_iot-prototyping_cover.jpg"
							alt=""
						/>
					</ImageFigure>
					<ImageFigure
						caption="Leading scandinavian medtech OEM used my prestudy to rapidly design, validate and develop a solution for remote ventilator view &ndash; during the height of the Covid-19 pandemic.">
						<StaticImage
							src="../images/aljaz-vindis_remote-ventilation_cover.jpg"
							alt=""
						/>
					</ImageFigure>
				</ImageGroup>
			</Container>
			<Container id="testemonials">
				<Heading as="h3" size="xl">Don't take my word for it</Heading>
				<TestemonialGroup>
					<Testemonial
						text="It took everyone just two weeks to clarify and align on what was actually needed. It would have been impossible without early access to actual users and their working environment."
						person="Product and team lead"
						position="Leading manufacturing company from Slovenia"
					/>
					<Testemonial
						text="The detailed prototype was key to collecting feedback from respiratory technicians, and determining the scope for MVP development."
						person="Global product manager, ICU ventilation"
						position="Leading OEM in Medical tech from Scandinavia"
					/>
					<Testemonial
						text="We had over 90% success rate with the prototype demos. Potential customers were literally asking me, when they can buy the licence. It made for a great funding pitch."
						person="Senior portfolio manager, IoT services"
						position="Leading OEM in Telecom tech from Scandinavia"
					/>
				</TestemonialGroup>
			</Container>
			<Container id="profile">
				<Heading as="h3" size="xl">So, who am I, exactly?</Heading>
				<Paragraph>Hi! I am Aljaž Vindiš (that's Al-yazh Win-dish), an internationally recognized designer &amp; mentor from Ljubljana, Slovenia. I build humanistic data products and world-class teams behind them.</Paragraph>
				<Paragraph>Over the last five years, I have deployed my prestudy format with numerous teams around the globe to reduce the risk of developing their new product. Latest collaborations include some of the core IoT services deployed today by tier 1 companies from Scandinavia.</Paragraph>
				<Paragraph>I currently work as an independent product consultant to growing companies in the space of data products. I this sounds like you, we can make your own product development more cost-effective too.</Paragraph>
				<ButtonNote>
					<Button type="cta" icon="event" label="Book your introduction" action={scrollToContact} />
					<Paragraph small={true} muted={true}>30 min call • Free of charge • No strings attached</Paragraph>
				</ButtonNote>
			</Container>
			<Container id="timeline">
				<Heading as="h3" size="xl">Prestudy, in a nutshell</Heading>
				<Paragraph>Think of it as an internal seed stage. In just two months, you will go from the raw idea to a fully-functional prototype, solid user validation, clear MVP production estimations, and ultimately a convincing funding pitch.</Paragraph>
				<div className="prestudy-timeline">
					<div className="prestudy-timeline__guides">
						<div className="prestudy-timeline__guide"></div>
						<div className="prestudy-timeline__guide"></div>
						<div className="prestudy-timeline__guide"></div>
						<div className="prestudy-timeline__guide"></div>
						<div className="prestudy-timeline__guide"></div>
						<div className="prestudy-timeline__guide"></div>
						<div className="prestudy-timeline__guide"></div>
						<div className="prestudy-timeline__guide"></div>
					</div>
					<div className="prestudy-timeline__entries">
						<div className="prestudy-timeline__workshop">
							<p className="prestudy-timeline__workshop-title">Workshop (others end here)</p>
							<span className="prestudy-timeline__workshop-graph">&nbsp;</span>
						</div>
						<div className="prestudy-timeline__research">
							<p className="prestudy-timeline__research-title">In-depth research</p>
							<span className="prestudy-timeline__research-graph">&nbsp;</span>
						</div>
						<div className="prestudy-timeline__prototyping">
							<p className="prestudy-timeline__prototyping-title">Prototype iterations</p>
							<span className="prestudy-timeline__prototyping-graph">&nbsp;</span>
						</div>
						<div className="prestudy-timeline__validation">
							<p className="prestudy-timeline__validation-title">Validation rounds</p>
							<span className="prestudy-timeline__validation-graph">&nbsp;</span>
						</div>
						<div className="prestudy-timeline__estimation">
							<p className="prestudy-timeline__estimation-title">Estimation &amp; funding pitch</p>
							<span className="prestudy-timeline__estimation-graph">&nbsp;</span>
						</div>
					</div>
				</div>
			</Container>
			<Container id="sales">
				<Heading as="h3" size="xl">Two ways we can do it</Heading>
				<PlanGroup>
					<Plan
						heading="A: Training"
						description="I take your team through a prestudy course, and provide feedback as they do their first. Best for teams with embedded product designers, looking to up their game with limited facilitation."
						includes={[
							"Free introduction call",
							"Team orientation session",
							"Lecture on prestudies",
							"Optional reading list",
							"Workflow due dilligence",
							"3 check-ins (1 per each stage)",
							"1 design review, when requested",
							"Follow-up performance review"
						]}
						partner="ScreenSaverz"
					/>
					<Plan
						heading="B: Collaboration"
						description="I embed with your team and work hands-on, facilitating delivery of each prestudy stage. Best for engineering-only teams, taking first steps into product design, while working on a real project."
						includes={[
							"Free introduction call",
							"Team orientation session",
							"Lecture on prestudies",
							"Workshop facilitation & report",
							"Optional reading list",
							"UX research & report",
							"Prototype sketches",
							"High-fidelity prototype",
							"UX validation & report",
							"Prototype demo walkthrough",
							"Technical research support",
							"Production estimation support",
							"Financing pitch support"
						]}
					/>
				</PlanGroup>
			</Container>
			<Container id="contact" >
				<Heading as="h3" size="xl">Book a free call</Heading>
				<ContactForm
					callLink="https://calendly.com/windish/contact"
					emailAddress="hello@vindis.io"
				/>
			</Container>
			<Container id="faq">
				<Heading as="h3" size="xl">FAQs</Heading>
				<AccordionGroup>
					<Accordion
						label="We did a design sprint, why do this?"
						content="Congratulations on your sprint! However, as you might have realized yourself, the output is intentionally rough. That makes scoping and estimation for actual development rather hard. My prestudy delves deeper into the most promising idea from your design sprint, leading to far more detailed prototypes and feedback. This makes user story breakdown, scoping, and estimation much more straightforward."
					/>
					<Accordion
						label="Who should be involved on our side?"
						content="At the bare minimum, your Product Manager should be present and act as a decision-maker. However, I have witnessed the best results when the prestudy team includes a technical expert, a field expert, a sales representative, and, through them, your friendly customers. This dramatically reduces the feedback loop and helps us challenge our assumptions as early as the workshop itself."
					/>
					<Accordion
						label="How much lead time do you need?"
						content="It’s primarily dependent on how quickly you can secure dedicated time for the needed participants. On my side, however, it depends on the travel and accommodation logistics. Any existing engagements may also require we start with an initial delay. The typical prestudy timeline applies once the process is started (as presented herein)."
					/>
					<Accordion
						label="Can all of this be done remotely?"
						content="Yes, it can! Most of it is done so by default, in regular contact with you, using highly transparent collaboration tools. However, remote facilitation of the workshop comes with a particular set of challenges, requiring more preparation and a slower workshop timeline (e.g., 1-2 sessions per day turn into 1-2 sessions per week). Therefore, if you wish to do the workshop remotely, please tell so up-front so that I can accommodate your needs accordingly."
					/>
					<Accordion
						label="Are there any 3rd parties involved?"
						content="My professional network consists of over 100 people in fields of System architecture, DevOps consulting, Backend and Frontend development, as well as Data engineering, Data science, and Data visualization. Many of them are senior, internationally acclaimed experts with whom I have long-standing relationships. When a prestudy requires specialized know-how that either you or I lack, I turn to them for support. If I were to work on my own venture, I would turn to these same people."
					/>
				</AccordionGroup>
			</Container>
		</Layout>
	)
}

// Page metadata
export const Head = () => (
	<Seo
		title={pageTitle}
		description={pageDescription}
	>
		<script type="application/ld+json">
			{`
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "name": "${pageTitle}",
          "description": "${pageDescription}",
          "url": "https://prestudy.vindis.io/",
          "copyrightHolder": "Aljaž Vindiš",
          "copyrightYear": 2022,
					"author": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					},
					"publisher": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					}
        }
      `}
		</script>
	</Seo>
)
